export const images = {
  banner: require('./homePage/banner.svg').default,
  avatar: require('./homePage/avatar.svg').default,
  // LoginPage
  backgroundMobile: require('./loginPage/backgroundMobile.svg').default,
  backgroundWeb: require('./loginPage/backgroundWeb.svg').default,
  telegram: require('./loginPage/telegram.svg').default,
  otpCode: require('./loginPage/otp-code.svg').default,
  hard: require('./loginPage/hard.svg').default,
  logo: require('./loginPage/logo.svg').default,
  logoEasyInvest: require('./homePage/logoEasyInvest.svg').default,
  logoEasyInvest2: require('./homePage/logoEasyInvest2.svg').default,
  logoEasyInvest3: require('./homePage/logoEasyInvest3.svg').default,
  // instruct
  b1: require('assets/images/instruct/B1.svg').default,
  b2: require('assets/images/instruct/B2.svg').default,
  b3: require('assets/images/instruct/B3.svg').default,
  b4: require('assets/images/instruct/B4.svg').default,
  b5: require('assets/images/instruct/B5.svg').default,
  b6: require('assets/images/instruct/B6.svg').default,
  b1Mobile: require('assets/images/instruct/b1Mobile.svg').default,
  b2Mobile: require('assets/images/instruct/b2Mobile.svg').default,
  b3Mobile: require('assets/images/instruct/b3Mobile.svg').default,
  b4Mobile: require('assets/images/instruct/b4Mobile..svg').default,
  b5Mobile: require('assets/images/instruct/b5Mobile.svg').default,
  b6Mobile: require('assets/images/instruct/b6Mobile.svg').default,

  // Instruction Page
  instruct1VN: require('assets/images/instructionPage/instruction_1_vn.svg').default,
  instruct2VN: require('assets/images/instructionPage/instruction_2_vn.svg').default,
  instruct3VN: require('assets/images/instructionPage/instruction_3_vn.svg').default,
  instruct4VN: require('assets/images/instructionPage/instruction_4_vn.svg').default,
  instruct5VN: require('assets/images/instructionPage/instruction_5_vn.svg').default,
  instruct6VN: require('assets/images/instructionPage/instruction_6_vn.svg').default,
  instruct7VN: require('assets/images/instructionPage/instruction_7_vn.svg').default,
  instruct8VN: require('assets/images/instructionPage/instruction_8_vn.svg').default,
  instruct9VN: require('assets/images/instructionPage/instruction_9_vn.svg').default,
  instruct10VN: require('assets/images/instructionPage/instruction_10_vn.svg').default,
  instruct11VN: require('assets/images/instructionPage/instruction_11_vn.svg').default,
  instruct12VN: require('assets/images/instructionPage/instruction_12_vn.svg').default,
  instruct13VN: require('assets/images/instructionPage/instruction_13_vn.svg').default,
  instruct14VN: require('assets/images/instructionPage/instruction_14_vn.svg').default,
  instruct15VN: require('assets/images/instructionPage/instruction_15_vn.svg').default,
  instruct16VN: require('assets/images/instructionPage/instruction_16_vn.svg').default,
  instruct17VN: require('assets/images/instructionPage/instruction_17_vn.svg').default,
  instruct18VN: require('assets/images/instructionPage/instruction_18_vn.svg').default,
  instruct19VN: require('assets/images/instructionPage/instruction_19_vn.svg').default,
  instruct20VN: require('assets/images/instructionPage/instruction_20_vn.svg').default,
  instruct21VN: require('assets/images/instructionPage/instruction_21_vn.svg').default,
  instruct22VN: require('assets/images/instructionPage/instruction_22_vn.svg').default,
  instruct23VN: require('assets/images/instructionPage/instruction_23_vn.svg').default,
  instruct24VN: require('assets/images/instructionPage/instruction_24_vn.svg').default,
};
