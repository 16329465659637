import React from 'react';
import { useDispatch } from 'react-redux';
import { Flex, Modal, Stack, Text, createStyles } from '@mantine/core';

import { authActions } from 'store/slice/auth';
import { OutlineButton } from '../Button/OutlineButton';
import { useTranslation } from 'react-i18next';
import { FilledButton } from '../Button/FilledButton';
import { systemActions } from 'store/slice/system';
import { ReactComponent as XCircle } from 'assets/icons/modal/x-circle.svg';
import { ReactComponent as IconAlert } from 'assets/icons/alert-circle.svg';

interface Props {
  isOpen: boolean;
}
const ModalSystemError = ({ isOpen }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(systemActions.resetSystemError());
  };

  const { classes } = makeStyles();

  return (
    <Modal
      centered
      opened={isOpen}
      onClose={() => handleCloseModal()}
      zIndex={99999999}
      closeButtonProps={{
        children: <XCircle />,
      }}
      classNames={{ content: classes.content }}
    >
      <Stack align="center">
        <IconAlert width={120} height={120} />
        <Text align="center">{t('modal.titleSystemError')}</Text>
      </Stack>
    </Modal>
  );
};

export default ModalSystemError;
const makeStyles = createStyles(() => ({
  content: {
    borderRadius: 8,
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    textAlign: 'center',
  },
  group: {
    gap: 12,
    width: '100%',
  },
  optionsBtn: {
    width: '50%',
  },
  closeModal: {
    display: 'none',
  },
  headerModal: {
    padding: '20px 0px',
    justifyContent: 'center',
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: 600,
  },
  innerModal: {
    zIndex: 9999,
  },
}));
