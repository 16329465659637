import { Modal, Stack, Text, createStyles } from '@mantine/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authActions } from 'store/slice/auth';
import { ReactComponent as XCircle } from 'assets/icons/modal/x-circle.svg';
import { ReactComponent as IconAlert } from 'assets/icons/alert-circle.svg';
import { useTranslation } from 'react-i18next';

interface ModalLockAccountProps {
  opened: boolean;
  type: 0 | 1;
}

const ModalLockAccount = ({ opened, type }: ModalLockAccountProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { classes } = makeStyles();
  const { t } = useTranslation();

  return (
    <Modal
      centered
      opened={opened}
      onClose={() => {
        if (type === 0) {
          dispatch(authActions.resetLogin());
        } else {
          dispatch(authActions.resetResponseLoginTelegram());
          navigate('/login');
        }
      }}
      closeButtonProps={{
        children: <XCircle />,
      }}
      classNames={{ content: classes.content }}
    >
      <Stack align="center">
        <IconAlert width={120} height={120} />
        <Text align="center">{t('modal.titleLocked')}</Text>
      </Stack>
    </Modal>
  );
};

const makeStyles = createStyles(() => ({
  content: {
    borderRadius: 8,
  },
}));

export default ModalLockAccount;
