import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { store } from 'store/configureStore';
import { authActions } from 'store/slice/auth';
import { coinActions } from 'store/slice/coin';
import { systemActions } from 'store/slice/system';
import { stakeActions } from 'store/slice/stake';
import { walletActions } from 'store/slice/wallet';

// import Storage from 'utils/Storage';
import { BaseResponse } from 'utils/http/response';
import { profileActions } from 'store/slice/profile';
import { transactionActions } from 'store/slice/transaction';
import { p2pActions } from 'store/slice/p2p';
import { rechargeActions } from 'store/slice/recharge';
import { withdrawActions } from 'store/slice/withdraw';
import { getCookie } from 'helpers/getCookie';

const BASEURL =
  process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_BASEURL_PROD : process.env.REACT_APP_BASEURL_DEV;

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];
// Set config defaults when creating the instance
export const authAxios = axios.create({
  baseURL: BASEURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
authAxios.interceptors.request.use(
  config => {
    // const token = Storage.getFieldOfUser('token');
    const stateStore = store.getState().auth;
    const id = stateStore.id;
    const token = stateStore.token;
    if (!token || !id) return config;

    if (token && config.headers) {
      // config.headers['Authorization'] = 'Bearer ' + token;
      if (['/ez/getlinklogin', '/ez/getotp', '/ez/loginbyotp', '/ez/loginbylink'].includes(config?.url as string)) return config;

      config.headers['token'] = token;
      config.headers['userid'] = id;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

const refreshAccessToken = (): Promise<AxiosResponse<any>> => {
  const stateStore = store.getState().auth;
  const refreshToken = stateStore.refreshToken;
  const deviceId = getCookie('device_id_iz');

  return authAxios.post('/ez/refreshtoken', { refresh_token: refreshToken, device_id: deviceId });
};

const setAccessToken = (accessToken: string) => {
  // Implement your logic to update the access token in your authentication system
  store.dispatch(authActions.setNewToken({ token: accessToken }));
};

const handleLogout = () => {
  store.dispatch(authActions.logoutSuccess());
  store.dispatch(coinActions.resetAllFieldOfCoin());
  store.dispatch(stakeActions.resetAllFieldOfStake());
  store.dispatch(walletActions.resetAllFieldOfWallet());
  store.dispatch(rechargeActions.resetAllFieldRecharge());

  store.dispatch(profileActions.resetAllFieldOfProfile());
  store.dispatch(transactionActions.resetAllFieldOfTransaction());
  store.dispatch(p2pActions.resetAllFieldOfP2P());
  store.dispatch(withdrawActions.resetAllFieldOfWithdraw());
};

// Add a response interceptor
authAxios.interceptors.response.use(
  async (res: any) => {
    try {
      // Refresh token here
      const originalConfig = res.config;
      const originalData = res.data as BaseResponse;
      const RESPONSE_ERROR_TOKEN = 3;
      const RESPONSE_SUCCESS_TOKEN = 0;
      const RESPONSE_MESSAGE_TOKEN = 'access_token_exp';

      if (originalData.error === RESPONSE_ERROR_TOKEN && originalData.message === RESPONSE_MESSAGE_TOKEN) {
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            const response = await refreshAccessToken();

            if (response.data.error === RESPONSE_SUCCESS_TOKEN) {
              const { token } = response.data.data;
              setAccessToken(token);
              refreshSubscribers.forEach(callback => callback(token));
              refreshSubscribers = [];

              originalConfig.headers['token'] = token;
            } else {
              handleLogout();
              return;
            }

            return authAxios(originalConfig);
          } catch (refreshError) {
            // Handle the refresh token error, e.g., redirect to login page
            // redirectToLogin();
            return Promise.reject(refreshError);
          } finally {
            isRefreshing = false;
          }
        } else {
          return new Promise(resolve => {
            refreshSubscribers.push(token => {
              originalConfig.headers['token'] = token;
              resolve(authAxios(originalConfig));
            });
          });
        }
      }

      handleAuthorized(res);
      handleSystemError(res);
      return res;
    } catch (error) {
      throw new Error(error);
    }
  },
  function (error: AxiosError) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

function handleAuthorized(res: AxiosResponse): AxiosResponse {
  const originalConfig = res.config;
  const originalData = res.data as BaseResponse;
  const RESPONSE_ERROR_AUTHORIZED = 2;
  const RESPONSE_MESSAGE_AUTHORIZED = 'unauthorized';

  if (
    !['/ez/getlinklogin', '/ez/getotp', '/ez/loginbyotp', '/ez/loginbylink', '/ez/refreshtoken'].includes(
      originalConfig?.url as string,
    ) &&
    originalData.error === RESPONSE_ERROR_AUTHORIZED &&
    originalData.message === RESPONSE_MESSAGE_AUTHORIZED
  ) {
    handleLogout();
    return res;
  }
  return res;
}

function handleSystemError(res: AxiosResponse): AxiosResponse {
  const originalData = res.data as BaseResponse;
  const RESPONSE_ERROR_SYSTEM_ERROR = 1;
  const RESPONSE_MESSAGE_SYSTEM_ERROR = 'system_error';

  if (originalData.error === RESPONSE_ERROR_SYSTEM_ERROR && originalData.message === RESPONSE_MESSAGE_SYSTEM_ERROR) {
    store.dispatch(systemActions.setSystemError());
    // throw new Error('system error');
  }
  return res;
}
// function fetchRetry(url, options = {}, retries = 3, backoff = 300) {
//   /* 1 */
//   const retryCodes = [408, 500, 502, 503, 504, 522, 524];
//   return fetch(url, options)
//     .then(res => {
//       if (res.ok) return res.json();

//       if (retries > 0 && retryCodes.includes(res.status)) {
//         setTimeout(() => {
//           /* 2 */
//           return fetchRetry(url, options, retries - 1, backoff * 2); /* 3 */
//         }, backoff); /* 2 */
//       } else {
//         throw new Error(res);
//       }
//     })
//     .catch(console.error);
// }
