export const dataBinanceAccount = {
  bankId: 1,
  bankName: 'Binance',
  nameReceiver: 'NickName - Lam Kiều Thị',
  accountNumber: 'ID - 873848374',
  avatarBank:
    'https://play-lh.googleusercontent.com/C3CpyPtocz_jypT6mEIuSEuQNy0_lSaA0fH05zuj079K38A1lY4XDdCC9N-2dVqGjc_X=w240-h480-rw',
  QRCode:
    'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1200px-QR_code_for_mobile_English_Wikipedia.svg.png',
};
