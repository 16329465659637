import { createSlice } from '@reduxjs/toolkit';
import { System } from './types';

export const initialState: System = {
  systemError: false,
};

const slice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    // reset
    resetSystemError(state: System) {
      state.systemError = false;
    },

    //
    setSystemError(state: System) {
      state.systemError = true;
    },

    resetAllFieldOfSystem(state: System) {
      return { ...initialState };
    },
  },
});

export const { actions: systemActions, reducer } = slice;

export const systemReducer = reducer;
